import React, { useState, useEffect } from 'react';
import checkIcon from 'assets/viasForm/circle-check.svg';
import indicator from 'assets/viasForm/Indicator.svg';
import uploadIcon from 'assets/viasForm/uploadIcon.svg';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import '../section.scss';
import { Fileupload } from 'components/common/fileupload/fileupload';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import api from 'appRedux/api';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchViasForms } from 'appRedux/actions/visaForm';
import {
  Largemodal,
  Simplemodal,
  SimpleCancelmodal,
} from 'components/common/simplifiedmodal';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { updateUploadedFiles, handleSections } from 'appRedux/actions/visaForm';
import errIcon from 'assets/viasForm/uploadError.svg';
import pathwayconfig from 'config.js';

const renderTitle = index => {
  switch (index) {
    case 0:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          Upload Data Page of International Passport{' '}
        </p>
      );

    default:
  }
};

export const Section1 = props => {
  const [isToggle, setIstoggle] = useState(false);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState({
    0: 1,
  });
  const [viewFile, setViewFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([{}]);
  const [numPages, setNumPages] = useState({
    0: 0,
  });
  const [pdfData, setPdfData] = useState(['']);
  const [myIndex, setMyIndex] = useState(0);

  const [submittedPdfData, setSubmittedPdfData] = useState(['']);
  const [myDict, setMyDict] = useState();

  useEffect(() => {
    if (Array.isArray(props.Forms)) {
      const newData = props.Forms?.map(data => ({
        immigrationPassport: data.immigrationPassport,
      }));
      setMyDict(newData);

      setPdfData([
        newData[0]?.immigrationPassport ? newData[0]?.immigrationPassport : '',
      ]);

      setSubmittedPdfData([
        newData[0]?.immigrationPassport ? newData[0]?.immigrationPassport : '',
      ]);
    }
  }, [props.Forms]);

  const fileNames = pdfData.map(url => url.split('/').pop());
  const nonEmptyLength = pdfData.filter(item => item !== '').length;

  const noOfSubmittedData = submittedPdfData.filter(item => item !== '').length;

  const [errorMsgs, setErrorMsgs] = useState(['', '']);

  const onFileLoad = (event, index) => {
    const file = event.target.files[0];

    // Check if file size exceeds the limit (1010 KB)
    if (file.size / 1024 > 10240) {
      // Reset selectedFiles[index] and pdfData[index] to empty state
      setSelectedFiles(prevSelectedFiles => {
        const newSelectedFiles = [...prevSelectedFiles];
        newSelectedFiles[index] = {};
        return newSelectedFiles;
      });

      setPdfData(prevPdfData => {
        const newPdfData = [...prevPdfData];
        newPdfData[index] = '';

        const newErrorMsgs = [...errorMsgs];
        newErrorMsgs[index] = 'File size exceeds the limit (10 MB)';
        setErrorMsgs(newErrorMsgs);

        // Clear the error message after 4 seconds
        setTimeout(() => {
          newErrorMsgs[index] = '';
          setErrorMsgs(newErrorMsgs);
        }, 2000);

        return newPdfData;
      });

      // Display error message (you can modify this part based on your UI)
      // setErrorMsg('File size exceeds the limit (1010 KB)');
      console.error('File size exceeds the limit (1010 KB)');
      return;
    }
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = file;
    setSelectedFiles(newSelectedFiles);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const newPdfData = [...pdfData];
      newPdfData[index] = reader.result;
      setPdfData(newPdfData);

      const selectedFileObject = {
        [index === 0 ? 'immigrationPassport' : 'dataPageOfPassport']: file,
      };

      // Dispatch the action to update the Redux store with the selected file object
      dispatch(updateUploadedFiles(selectedFileObject));
    };
  };

  const onDocumentLoadSuccess = ({ numPages }, index) => {
    setNumPages(prevNumPages => ({
      ...prevNumPages,
      [index]: numPages,
    }));
  };

  const [progress, setProgress] = useState(0);
  const [uploadedDoc, setUploadedDoc] = useState(false);
  const [triggerwarning, setTriggerWarning] = useState(false);
  const [buttontext, setButtonText] = useState('Save Section & Enter Lottery');

  useEffect(() => {
    if (uploadedDoc) {
      const interval = setInterval(() => {
        setUploadedDoc(true);

        if (progress < 100) {
          setProgress(prevProgress => prevProgress + 1);
        } else {
          clearInterval(interval);
          setUploadedDoc(false);

          setViewFile(true);

          setProgress(0);
        }
      }, 70);
      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [progress, uploadedDoc, props.loading]);

  const updateApplication = async () => {
    setSelectedFiles([{}, {}]);
    props.setLoading(true);
    setButtonText('Saving...');

    var url = `${pathwayconfig.baseURL}/pathway/update-visa-documents`;

    const formData = new FormData();

    if (selectedFiles[0]?.name) {
      formData.append('immigrationPassport', selectedFiles[0]);
    }

    // formData.append('dataPageOfPassport', selectedFiles[1]);

    formData.append('email', props.userD?.email);
    formData.append('id', props.Forms[0]?.id);
    formData.append('userId', props.userD?.id);
    formData.append('submitted', true);
    formData.append('hasWatchedPathwayVideo', true);
    // formData.append('formStage', 2);
    // obianuju.udeagbala@gmail.com
    formData.append(
      'hasCompanyFilling',
      props.state.recruitingCompany === 'I have company filing for me'
        ? true
        : false,
    );
    formData.append('dateOfBirth', props.state.dob);
    formData.append('firstName', props.state.firstName);
    formData.append('lastName', props.state.lastName);
    formData.append(
      'fullname',
      `${props.state.firstName} ${props.state.lastName}`,
    );

    formData.append(
      'hasBachelorsDegree',
      props.state.degree === 'Yes' ? true : false,
    );
    formData.append('middleName', props.state.middleName);
    // formData.append(
    //   'companyFilling',
    //   props.state.recruitingCompany === 'I have company filing for me'
    //     ? props.state.companyName
    //     : props.state.recruitingCompany,
    // );
    formData.append(
      'companyFilling',
      props.state.recruitingCompany === 'I have company filing for me'
        ? props.state.companyName
        : 'N/A',
    );

    if (props.state.recruitingCompany === 'I have company filing for me') {
      formData.append('companyFillingName', props.state.companyName);
      formData.append('companyFillingEIN', props.state.Ein);
      formData.append('companyFillingEmail', props.state.employerEmail);
      formData.append('companyFillingPhoneNumber', props.state.employerContact);
      formData.append('companyFillingAddress', props.state.EmployerAddress);
    }

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      props.setLoading(false);

      dispatch(
        props.fetchViasForms(
          props.userD?.email,
          props.userD?.email,
          props.visaType,
          props.userD?.firstName,
        ),
      );
      dispatch(updateUploadedFiles(null));

      setButtonText('Save section');
      openNotificationWithIcon('', 'Saved');
    } catch (error) {
      console.error('Error uploading file:', error);
      setButtonText('Save section');
      props.setLoading(true);
    }
  };

  const optionalSection = useSelector(
    state => state.visaForm.mysections.optional,
  );
  const requiredSection = useSelector(
    state => state.visaForm.mysections.required,
  );

  const getItem = async () => {
    if (myDict && myDict[0]) {
      props.setLoading(true);

      const firstItem = myDict[0];
      const keyValuePairs = Object.entries(firstItem);
      // Now keyValuePairs is an array of arrays, each inner array representing a key-value pair
      // Accessing the first key-value pair
      const firstKeyValuePair = keyValuePairs[myIndex];
      const key = firstKeyValuePair[0]; // The key
      const value = firstKeyValuePair[1]; // The value

      const url = `${pathwayconfig.baseURL}/pathway/delete-file-visa-documents`;

      const payload = {
        formId: props.Forms[0]?.id,
        item: key,
      };

      api
        .post(url, payload)
        .then(res => {
          console.log(res);
          props.setLoading(false);

          dispatch(
            props.fetchViasForms(
              props.userD?.email,
              props.userD?.email,
              props.visaType,
              props.userD?.firstName,
            ),
          );

          openNotificationWithIcon('Visa Form', 'Saved');
        })
        .catch(error => {
          console.error('Error deleting file:', error);
          props.setLoading(false);
          //  openNotificationWithIconErr(error.data.message, 'Register', 'error');
        });
    }
  };

  useEffect(() => {
    const submitteddataIsallFilled = submittedPdfData?.every(
      data => data !== '',
    );

    if (submitteddataIsallFilled) {
      dispatch(handleSections({ section12: 'done' }, { ...optionalSection }));
    } else {
      delete requiredSection.section12;

      dispatch(handleSections({ ...requiredSection }, { ...optionalSection }));
    }
  }, [submittedPdfData, props.loading]);

  return (
    <div>
      <Largemodal open={viewFile} onClick={() => setViewFile(false)}>
        <div>
          <Document
            file={pdfData[myIndex]}
            onLoadSuccess={({ numPages }) =>
              onDocumentLoadSuccess({ numPages }, myIndex)
            }
          >
            <Page pageNumber={pageNumber[myIndex]} />
          </Document>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                setPageNumber(prevPageNumber => ({
                  ...prevPageNumber,
                  [myIndex]: Math.max(prevPageNumber[myIndex] - 1, 1),
                }));
              }}
              disabled={pageNumber[myIndex] <= 1}
              style={{
                color: '#67A948',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Back
            </button>

            <p>
              Page {pageNumber[myIndex]} of {numPages[myIndex]}
            </p>

            <button
              onClick={() => {
                setPageNumber(prevPageNumber => ({
                  ...prevPageNumber,
                  [myIndex]: Math.min(
                    prevPageNumber[myIndex] + 1,
                    numPages[myIndex],
                  ),
                }));
              }}
              disabled={pageNumber[myIndex] >= numPages[myIndex]}
              style={{
                color: '#67A948',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Largemodal>

      <SimpleCancelmodal
        open={triggerwarning}
        onClick={() => setTriggerWarning(false)}
      >
        <div style={{ marginTop: '20px' }}>
          You are permanently deleting this document and would not be able to
          retrieve it once deleted
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginTop: '14px',
            }}
          >
            <div
              onClick={() => {
                setSelectedFiles(prevSelectedFiles => {
                  const newSelectedFiles = [...prevSelectedFiles];
                  newSelectedFiles[myIndex] = {};
                  return newSelectedFiles;
                });
                setPdfData(prevPdfData => {
                  const newPdfData = [...prevPdfData];
                  newPdfData[myIndex] = '';
                  return newPdfData;
                });
                setTriggerWarning(false);
                getItem();
              }}
              style={{
                background: '#67A948',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              Continue
            </div>

            <div
              style={{
                background: 'red',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setTriggerWarning(false);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </SimpleCancelmodal>

      <div className="" style={{ position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <div>
            {[0].map(index =>
              pdfData[index] === '' ? (
                <div key={index}>
                  {renderTitle(index)}
                  <input
                    type="file"
                    id={`fileInput${index}`}
                    // accept=".pdf"
                    accept=".pdf"
                    onChange={event => {
                      onFileLoad(event, index);
                    }}
                    style={{
                      display: 'none',
                    }}
                  />

                  <label
                    htmlFor={`fileInput${index}`}
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                    }}
                    className="visaUploadInputs"
                  >
                    <img
                      src={uploadIcon}
                      alt=""
                      style={{
                        margin: '0px auto',
                      }}
                    />
                    Click to upload
                    <p
                      style={{
                        color: '#14290A',
                        display: 'block',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Upload PDF only (Max. File size: 10 MB)
                    </p>
                  </label>

                  {errorMsgs[index] ? (
                    <div className="visaFileLimitError">
                      {' '}
                      <img src={errIcon} alt="" /> {errorMsgs[index]}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>
                  <>{renderTitle(index)}</>
                  <div
                    className="visaFormSectionContainer__subsections"
                    key={index}
                  >
                    <div className="visaFormSectionContainer__subsectionsFlex">
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <IoDocumentTextOutline />
                        <div>
                          <p
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                              color: '#14290A',
                            }}
                          >
                            {selectedFiles[index]?.name
                              ? selectedFiles[index]?.name
                              : pdfData[index]?.split('/').pop()}
                          </p>
                          <>
                            {selectedFiles[index]?.size ? (
                              <p
                                style={{
                                  color: '#98A2B3',
                                  fontSize: '13px',
                                  margin: '12px 0px',
                                }}
                              >
                                {' '}
                                {Math.round(
                                  selectedFiles[index]?.size / 1024,
                                )}{' '}
                                KB
                              </p>
                            ) : (
                              <div
                                style={{
                                  marginTop: '12px',
                                }}
                              >
                                <a
                                  href={`${pdfData[index]}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: '#67A948',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Click to view
                                </a>
                              </div>
                            )}
                          </>

                          {(uploadedDoc && myIndex === index) ||
                          !selectedFiles[index]?.size ? (
                            <p> </p>
                          ) : (
                            <p
                              style={{
                                color: '#67A948',
                                fontSize: '13px',
                                fontWeight: '600',
                                // cursor: 'pointer',
                              }}
                              // onClick={() => {
                              //   setMyIndex(index);
                              //   setUploadedDoc(true);
                              // }}
                            >
                              {/* Click to view */}
                            </p>
                          )}
                        </div>
                      </div>

                      {props.Forms[0]?.submitted ? (
                        <></>
                      ) : (
                        <>
                          {selectedFiles[index]?.name ? (
                            <RiDeleteBinLine
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedFiles(prevSelectedFiles => {
                                  const newSelectedFiles = [
                                    ...prevSelectedFiles,
                                  ];
                                  newSelectedFiles[index] = {};
                                  return newSelectedFiles;
                                });
                                setPdfData(prevPdfData => {
                                  const newPdfData = [...prevPdfData];
                                  newPdfData[index] = '';
                                  return newPdfData;
                                });
                              }}
                            />
                          ) : (
                            <RiDeleteBinLine
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setMyIndex(index);

                                setTriggerWarning(true);
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>

                    {uploadedDoc && myIndex === index ? (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '4px',
                            marginBottom: '8px',
                            height: '7px',
                          }}
                        >
                          <div
                            style={{
                              width: `${progress}%`,
                              height: '100%',
                              backgroundColor: '#67A948',
                              borderRadius: '4px',
                              transition: 'width 0.1s ease-in-out',
                            }}
                          />
                        </div>
                        <p
                          style={{
                            fontSize: '14px',
                          }}
                        >{`${progress}%`}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              ),
            )}
          </div>

          <div
            style={{
              margin: '1rem 0px 0.25rem 0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div></div>

            {props.Forms[0]?.submitted ? (
              <></>
            ) : (
              <>
                {' '}
                {// selectedFiles[0]?.size || selectedFiles[1]?.size
                // props.isClarityVideoChecked &&
                // selectedFiles[0]?.size ||
                props.Forms[0]?.paymentPercent == 100 &&
                // props.Forms[0]?.immigrationPassport &&
                // props.state.firstName &&
                // props.state.middleName &&
                // props.state.lastName &&
                props.state.dob &&
                props.state.degree &&
                // props.state.recruitingCompany
                // &&
                (props.state.recruitingCompany ===
                'I have company filing for me'
                  ? props.state.EmployerAddress
                  : true) &&
                (props.state.recruitingCompany ===
                'I have company filing for me'
                  ? props.state.companyName
                  : true) &&
                (props.state.recruitingCompany ===
                'I have company filing for me'
                  ? props.state.Ein
                  : true) &&
                (props.state.recruitingCompany ===
                'I have company filing for me'
                  ? props.state.employerEmail
                  : true) &&
                (props.state.recruitingCompany ===
                'I have company filing for me'
                  ? props.state.employerContact
                  : true) ? (
                  <p
                    style={{
                      background: 'green',
                      padding: '15px 25px',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginTop: '2rem',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      updateApplication();
                      // props.Forms[0]?.id
                      //   ?
                      //   : submitApplication();
                    }}
                  >
                    {buttontext}
                  </p>
                ) : (
                  <p
                    style={{
                      background: '#4C5366',
                      padding: '15px 25px',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginTop: '2rem',
                      borderRadius: '10px',
                      cursor: 'not-allowed',
                    }}
                    onClick={() => {}}
                  >
                    {buttontext}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
