import React, { useState, useEffect } from 'react';
import '../components.css';
import { Link } from '@reach/router';
import { Tooltip } from 'antd';
// import vestilogowhite from '../../assets/vestilogowhite.svg';
import vestilogowhite from '../../assets/xmasLogo/XmasWhiteLogo.png';

import { userSignOut } from '../../appRedux/actions/auth';
import { connect } from 'react-redux';
import { logout, provider } from '../../assets/assets';
import './sidebar.scss';
import { sidebarMenus } from 'helpers/data';
import { sidebarAccountMenus } from 'helpers/data';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';

const SideBar = props => {
  const isProvider = props.userData?.userType === 'PROVIDER';

  const userData = useSelector(state => state.auth?.userData);
  // console.log(userData);

  const [membership, setmembership] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchPlanType = async () => {
      try {
        const response = await api.get(`pathway/plan-and-join`);
        if (response.data?.data.plan === null || !response.data?.data.plan) {
          setmembership('Economy');
        } else if (response.data?.data?.plan?.type === 'BUSINESS') {
          setmembership('Business Class');
        } else if (response.data?.data?.plan?.type === 'PREMIUM') {
          setmembership('First Class');
        } else {
          setmembership('Economy');
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchPlanType();
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar__content">
        <a href="https://www.wevesti.com/" className="sidebar__vestilogo">
          <img
            src={vestilogowhite}
            alt=""
            // style={{ width: '120px' }}
            className="sidebar__logo"
          />
        </a>

        <div className="sidebar__links">
          {isProvider && (
            <>
              <Tooltip placement="right" title="Dashboard">
                <NavLink to="/dashboard">
                  <div className="sidebar-links provider">
                    <img src={provider} alt="Provider SVG" />
                    <p className="side_sub_txt">Provider Dashboard</p>
                  </div>
                </NavLink>
              </Tooltip>
            </>
          )}
          {sidebarMenus.map((item, index) => (
            <Tooltip key={index} placement="right" title={item.tip}>
              <NavLink to={item.route}>
                <div className="sidebar-links">
                  <img src={item.image} alt="Feeds SVG" />
                  <p className="side_sub_txt">{item.name}</p>
                  <div className="--sidebar"></div>
                </div>
              </NavLink>
            </Tooltip>
          ))}
        </div>

        <div style={{ marginTop: '20px' }} className="sidebar__links">
          <h5
            style={{
              color: '#fff',
              fontSize: '24px',
              fontWeight: '450',
              marginLeft: '2px',
              marginBottom: '-0.05rem',
            }}
          >
            Account
          </h5>
          {sidebarAccountMenus.map((item, index) => (
            <Tooltip key={index} placement="right" title={item.tip}>
              <NavLink to={item.route}>
                <div className="sidebar-links">
                  <img src={item.image} alt="Feeds SVG" />
                  <p className="side_sub_txt">{item.name}</p>
                  <div className="--sidebar"></div>
                </div>
              </NavLink>
            </Tooltip>
          ))}
          <Tooltip placement="right" title="Log Out">
            <Link onClick={props.userSignOut} to="/auth">
              <div className="sidebar-links logout">
                <img src={logout} alt="logout SVG" />
                <p className="side_sub_txt">Logout</p>
              </div>
            </Link>
          </Tooltip>
        </div>

        <div
          style={{ marginTop: '20px', display: 'flex' }}
          className="sidebar__links"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {userData?.profilePictureURL === null ? (
              <h5
                style={{
                  color: '#518C36',
                  fontSize: '16px',
                  fontWeight: 'normal',
                  background: '#DFFFD0',
                  // padding: '10px',
                  borderRadius: '50%',
                  height: '50px',
                  width: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {' '}
                {userData?.firstName?.charAt(0)}
                {userData?.lastName?.charAt(0)}
              </h5>
            ) : (
              <img
                style={{
                  borderRadius: '50%',
                  height: '50px',
                  width: '50px',
                }}
                src={userData?.profilePictureURL}
                alt="Profile Pic"
              />
            )}

            <div
              style={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 'normal',
                marginLeft: '5px',
              }}
            >
              {userData?.firstName} {userData?.lastName?.charAt(0)}.
              <h5
                style={{
                  marginTop: '5px',
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: '200',
                  marginLeft: '5px',
                }}
              >
                {membership}
              </h5>
            </div>
          </div>
        </div>
        {/* <div className="sidebar__links"></div> */}
      </div>
      <div className="side_content_box" />
    </div>
  );
};

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => ({
      className: isCurrent ? 'active' : '',
    })}
  />
);

const mapStateToProps = ({ auth }) => {
  const { userData } = auth;
  return {
    userData,
  };
};

export default connect(mapStateToProps, {
  userSignOut,
})(SideBar);
