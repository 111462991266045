/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import RouteComponent from './routes/RouteComponent';

import './App.css';
import PageLayout from './containers/PageLayout/PageLayout';
import configureStore from './appRedux/store';
import { QueryClient, QueryClientProvider } from 'react-query';

import ReactGA from 'react-ga';
// import { FloatButton } from 'antd';
import IframeComponent from 'components/website/Iframe.mia';
import FloatingButton from 'components/common/floatingbutton/FloatingButton';
import { navigate } from '@reach/router';

export const store = configureStore();
const queryClient = new QueryClient();
ReactGA.initialize('G-CY10JKD1J3');
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  const [showIframe, setShowIframe] = useState(false);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  // start
  // var addHandler = function(element, type, handler) {
  //   if (element.addEventListener) {
  //     element.addEventListener(type, handler, false);
  //   } else if (element.attachEvent) {
  //     element.attachEvent('on' + type, handler);
  //   } else {
  //     element['on' + type] = handler;
  //   }
  // };

  // var preventDefault = function(event) {
  //   if (event.preventDefault) {
  //     event.preventDefault();
  //   } else {
  //     event.returnValue = false;
  //   }
  // };

  // addHandler(window, 'contextmenu', function(event) {
  //   preventDefault(event);
  // });
  // document.onkeydown = function(event) {
  //   if (event.keyCode == 123) {
  //     return false;
  //   } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
  //     return false;
  //   }
  // };
  // stop here
  return (
    <>
      {/* {splitLocation[2] === '0-1visa' ||
      splitLocation[2] === 'EB-1visa' ||
      splitLocation[2] === 'EB-2NIWvisa' ||
      splitLocation[2] === 'H1Bvisa' ? (
        <FloatingButton
          shape="circle"
          type="default"
          title="Miai (beta)"
          onClick={() => navigate('/pathways/chat')}
        />
      ) : (
        <></>
      )} */}

      {/* {showIframe && (
        <div className="popup">
          {<IframeComponent onClick={() => setShowIframe(false)} />}
        </div>
      )} */}

      <QueryClientProvider client={queryClient}>
        <PageLayout>
          <RouteComponent />
        </PageLayout>
      </QueryClientProvider>
    </>
  );
}

export default App;
